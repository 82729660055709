import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from "axios";

Vue.prototype.$http = axios;
Vue.config.productionTip = false;
import VueGoodTablePlugin from "vue-good-table";

// import the styles
import "vue-good-table/dist/vue-good-table.css";
Vue.config.productionTip = false;

Vue.use(VueGoodTablePlugin);
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
