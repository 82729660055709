<template>
  <div>
    <v-layout row wrap>
      <v-container justify-content="center" witdh="100%">
        <v-row>
          <v-col cols="12" justify-content="center" sm="12">
            <v-card hover justify="center">
              <v-card-title>Race Times</v-card-title>
              <v-card-text>
                <v-icon size="360%">mdi-delete-clock</v-icon>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary">Set All to Zero</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card hover :loading="generateTeamsLoading">
              <v-card-title>Teams</v-card-title>
              <v-card-text>
                <v-icon size="360%"> mdi-account-group </v-icon>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="confirmGenerateTeams()"
                  >Generate</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="confirmDestroyTeams()">Destroy</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card hover>
              <v-card-title>Messaging </v-card-title>
              <v-card-text>
                <v-icon size="360%">mdi-chart-timeline</v-icon>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="smsDialog = true">Send SMS</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card hover disabled>
              <v-card-title>Results </v-card-title>
              <v-card-text>
                <v-icon size="360%">mdi-upload</v-icon>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary">Upload CSV</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
    <v-snackbar v-model="errorPopup" timeout="2000" color="red darken-4">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="successPopup" timeout="2000" color="green">
      {{ successMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="successPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <ConfirmDlg ref="confirm" />
                   <v-dialog
                    v-model="smsDialog"
                    persistent
                    max-width="800px"
                    min-width="350px"
                  >
                
                    <v-card
                      ><v-form ref="smsAllForm">
                        <v-card-title>
                          <span class="text-h5">Send SMS</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="8">You can send a message to all racers we have phone numbers for. This will cost approx $15 per message.</v-col>
                            </v-row><v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                  label="SMS"
                                  required
                                  solo
                                  hint="Enter your message.."
                                  v-model="smsText"
                                  :rules="smsRules"
                                  :counter="160"
                                  
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="smsDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="submitSMS()"
                          >
                            Save
                          </v-btn>
                        </v-card-actions></v-form
                      >
                    </v-card>
                  </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      generateTeamsLoading: false,
      successPopup: false,
      errorPopup: false,
      errorMsg: "",
      successMsg: "",
      smsDialog: false,
      smsText: "",
      smsRules: [
        (v) => !!v || "Message is required",
        (v) =>
          (v && v.length <= 160) ||
          "SMS Message must be less than 160 characters",
      ],
    };
  },
  components: {
    ConfirmDlg: () => import("../components/ConfirmDlg"),
  },
  methods: {
    async submitSMS() {
      if (this.$refs.smsAllForm.validate()) {
        try {
          
          const response = await axios.post(
            "https://dev.app1.rsc.org.nz/sms/everyone",
            {
              message: this.smsText,
            }
                    );
          // JSON responses are automatically parsed.
          console.log(response);
          //this.racers = response.data;
        } catch (error) {
          console.log(error);
          this.errorMsg = error;
          this.errorPopup = true;
        } finally {
          this.smsDialog  = false;
          this.cancelNewRacer();
          if (!this.errorPopup) {
            this.successMsg = "Success! Message Sent!";
            this.successPopup = true;
            
          }
        }
      }
    },
    async confirmGenerateTeams() {
      var confMsg = "This will generate the teams again. Are you sure?";

      if (
        await this.$refs.confirm.open("Confirm", confMsg, {
          color: "red darken-2",
        })
      ) {
        this.getGenerateTeams();
      }
    },
       async confirmDestroyTeams() {
      var confMsg = "This will remove all team assignments. You will have to create the teams again. Are you sure?";

      if (
        await this.$refs.confirm.open("Confirm", confMsg, {
          color: "red darken-2",
        })
      ) {
        this.getDestroyTeams();
      }
    },
        async getDestroyTeams() {
      this.generateTeamsLoading = true;
      try {
        const response = await axios.put(
          "https://dev.app1.rsc.org.nz/teams/destroy"
        );
        // JSON responses are automatically parsed.
        console.log(response);
        if (response.status == 200) {
          //success
          this.successMsg = "OK! Teams have been destroyed";
          this.successPopup = true;
        }
        this.generateTeams = response.code;
      } catch (error) {
        console.log(error);
      } finally {
        this.generateTeamsLoading = false;
      }
    },
    async getGenerateTeams() {
      this.generateTeamsLoading = true;
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/teams/generate"
        );
        // JSON responses are automatically parsed.
        console.log(response);
        if (response.status == 200) {
          //success
          this.successMsg = "OK! Teams have been generated";
          this.successPopup = true;
        }
        this.generateTeams = response.code;
      } catch (error) {
        console.log(error);
      } finally {
        this.generateTeamsLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.home {
}
.bgImg {
  position: absolute;
  bottom: 0;
  left: 90%;
}

.icon .bigbutton {
  font-size: 80px;
}
v.reponsive {
  overflow: unset;
}
</style>
