<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app temporary>
      <!--  -->
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item to="/">
            <v-list-item-icon><v-icon>mdi-wrench </v-icon></v-list-item-icon>
            <v-list-item-title>Tools</v-list-item-title>
          </v-list-item>
          <v-list-item to="/divisions">
            <v-list-item-icon><v-icon>mdi-division </v-icon></v-list-item-icon>
            <v-list-item-title>Divisions</v-list-item-title>
          </v-list-item>

          <v-list-item to="/bibs">
            <v-list-item-icon
              ><v-icon>mdi-numeric-1-box-multiple </v-icon></v-list-item-icon
            >
            <v-list-item-title>Bibs</v-list-item-title>
          </v-list-item>

          <v-list-item to="/clubs">
            <v-list-item-icon
              ><v-icon>mdi-shield-home </v-icon></v-list-item-icon
            >
            <v-list-item-title>Clubs</v-list-item-title>
          </v-list-item>

          <v-list-item to="/racers">
            <v-list-item-icon><v-icon>mdi-ski </v-icon></v-list-item-icon>
            <v-list-item-title>Racers</v-list-item-title>
          </v-list-item>
          <v-list-item to="/teams">
            <v-list-item-icon
              ><v-icon>mdi-account-group </v-icon></v-list-item-icon
            >
            <v-list-item-title>Teams</v-list-item-title>
          </v-list-item>
          <v-list-item to="/results">
            <v-list-item-icon><v-icon>mdi-podium </v-icon></v-list-item-icon>
            <v-list-item-title>Results</v-list-item-title>
          </v-list-item>
          <v-list-item to="/times">
            <v-list-item-icon><v-icon>mdi-timer </v-icon></v-list-item-icon>
            <v-list-item-title>Times</v-list-item-title>
          </v-list-item>
          <v-list-item to="/lists">
            <v-list-item-icon
              ><v-icon>mdi-clipboard-list </v-icon></v-list-item-icon
            >
            <v-list-item-title>Lists</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block> Logout </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="blue-grey" dark class="d-print-none">
      <v-app-bar-nav-icon @click="drawer = !drawer"> </v-app-bar-nav-icon>

      <v-toolbar-title>Haensli Cup - {{ this.$route.name }} </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <!--  -->
      <v-container fluid>
        <div id="app">
          <router-view />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  data: () => ({ drawer: null, group: null }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
<style lang="scss">
@media print {
  .v-content {
    padding: 0 !important;
  }
  .v-toolbaar {
    display: none;
  }
}

.v-main {
  background: url("../src/assets/haensli-transparent.png") right bottom
    no-repeat;
  background-size: 10%;
  height: 10;
}
.vgt-fixed-header {
  z-index: 5 !important;
}
html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#dataTable {
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
