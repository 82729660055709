import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/divisions",
    name: "Divisions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Divisions.vue"),
  },
  {
    path: "/bibs",
    name: "Bibs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Bibs.vue"),
  },
  {
    path: "/clubs",
    name: "Clubs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Clubs.vue"),
  },
  {
    path: "/racers",
    name: "Racers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Racers.vue"),
  },
  {
    path: "/results/:division",
    name: "Division Results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Result.vue"),
  },
  {
    path: "/results",
    name: "Results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Results.vue"),
  },
  {
    path: "/teams",
    name: "Teams",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Teams.vue"),
  },
  {
    path: "/times",
    name: "Times",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Times.vue"),
  },
  {
    path: "/lists",
    name: "Lists",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Lists.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
